<template>
  <div class="meeting_course_preview_container global_full_screen" >
    <div class="meeting_course_preview_tips_save">
        <div class="meeting_course_preview_tips_save_countLength">
            <div class="meeting_course_preview_step_time">
                <span>字数：</span>
                <span :class="{meeting_course_preview_time_count:contentLength > maxCount}">{{contentLength}}</span>
                <span>/{{maxCount}}</span>
            </div>
            <!-- <customButton class="meeting_course_preview_voice" size="small"><div>点击录音</div></customButton> -->
        </div>
        <div class="meeting_course_preview_tips_save_title" v-if="!(editContent || contenteditable)">解说词区</div>
        <!-- <div class="meeting_course_preview_tips_save_buttonrow"> -->
            <customButton class="meeting_course_preview_tips_save_button" v-if="editContent || contenteditable" :class="{meeting_course_preview_update:true}" @click="restoreDefault()" ><div>恢复默认</div></customButton>
            <customButton class="meeting_course_preview_tips_save_button" v-if="(editContent || contenteditable) && isShowClear()" :class="{meeting_course_preview_update:true}" @click="clearInfo()" ><div>清空</div></customButton>
            <customButton class="meeting_course_preview_tips_save_button" v-if="!contenteditable && isHaveDoctorPage() && !isBaseTemplate()"  :class="{meeting_course_preview_update:true}" @click="updateContent()" ><div>{{getEditContentButtonName(editContent)}}</div></customButton>
            <customButton class="meeting_course_preview_tips_save_button" v-if="!editContent" :class="{meeting_course_preview_update:true}" @click="updateDescription(contenteditable)" ><div>{{getEditDescriptButtonName(contenteditable)}}</div></customButton>
        <!-- </div> -->
    </div>
    <div class="meeting_course_preview_room global_meeting_padding" ref="courseRoom" >
        <div class="meeting_course_preview_room_proxy global_full_screen" ref="scrollDOM">
            <!-- <itemScroll ref="itemScroll" > -->
                <!-- <el-input
                    v-model="content"
                    :autosize="{ minRows: 2, maxRows: 4 }"
                    type="textarea"
                    placeholder="Please input"
                >
                </el-input> -->
                <div class="meeting_course_preview_content" id="courseContent" ref="courseContent"  @compositionstart="compositionStart" @compositionend="compositionEnd"  @input="changeContent" @focus="focusContent" :contenteditable="contenteditable"  @paste="pasteEvent">
                    {{content}}
                </div>
                <!-- <div class="meeting_course_preview_content"  >
                    <el-input type="textarea" autosize v-model="content" @change="changeCourseContent"></el-input>
                </div> -->

            <!-- </itemScroll> -->
        </div>
        <!-- <div class="global_container_mask"></div> -->
    </div>
    <div class="meeting_course_preview_group global_meeting_padding">
        <!-- <div class="meeting_course_preview_step_button">
            <customButton class="meeting_course_preview_step" @click="showPopop('pptxDownload')" ><div>幻灯下载</div></customButton>
        </div>
        <div class="meeting_course_preview_step_button">
            <customButton class="meeting_course_preview_step" @click="showPopop('originalHelp')" ><div>原文求助</div></customButton>
        </div> -->
        <div class="meeting_course_preview_step_button" v-if="meetingData?.doctor_index !== 0">
            <customButton class="meeting_course_preview_step" @click="doPrepPage" ><div>上一页</div></customButton>
        </div>
        <div class="meeting_course_preview_step_button"  v-if="meetingData?.meet_info.can_add && isFullPage()" >
            <customButton class="meeting_course_preview_step" @click="doNewPage"><div>新增一页</div></customButton>
        </div>
        <div class="meeting_course_preview_step_button" v-if="['COMMONTEMPLATE'].includes(meetingData?.class_info.content[meetingData.doctor_index].template_key)" >
            <customButton class="meeting_course_preview_step" @click="doDeletePage" ><div>删除此页</div></customButton>
        </div>
        <div class="meeting_course_preview_step_button">
            <customButton class="meeting_course_preview_step" @click="doCommit(true,true)" ><div>{{getButtonText()}}</div></customButton>
        </div>
    </div>
    <opacityBg @click="showPopup=false" v-show="showPopup">
            <div class="meeting_course_preview_help_block" @click.stop="">
                 <p class="meeting_course_preview_help_tips">{{getPopopText()}}</p>
                 <div class="meeting_course_preview_help_email">
                     <p>输入邮箱：</p>
                     <el-input v-model="doctorInfo.email" class="global_text_input meeting_course_preview_help_input" placeholder="请输入您的邮箱"></el-input>
                 </div>
                 <div class="meeting_course_preview_help_buttonGroup">
                     <customButton class="meeting_course_preview_help_button" size="small" @click="commitEmail">
                        <div class="meeting_course_preview_help_button_info">
                            <span>确认</span>
                        </div>
                    </customButton>
                    <customButton class="meeting_course_preview_help_button" size="small" @click="hidePopup">
                        <div class="meeting_course_preview_help_button_info">
                            <span>取消</span>
                        </div>
                    </customButton>
                 </div>
             </div>
         </opacityBg>
        <Popup :cfg="popupCfg" v-if="showAddPage" @click="()=>{ this.showAddPage = false }">
            <div class="meeting_course_preview_tip_content">
                <div class="meeting_course_preview_tip_content_text">新增至</div>
                <div class="meeting_course_preview_add_page">
                    <customButton class="meeting_course_preview_help_button" v-if="meetingData?.doctor_index !== 0" size="small" @click="addPage(0)">
                        本页前
                    </customButton>
                    <customButton class="meeting_course_preview_help_button" size="small" @click="addPage(1)">
                        本页后
                    </customButton>
                </div>
            </div>
        </Popup>
        <Popup :cfg="popupCfg" v-if="showFirstTip" >
            <div class="meeting_course_preview_tip_content">
                <div class="meeting_course_preview_tip_content_text" v-html="`请确认您已输入您的参赛语录，<span><span class='global_text_tag'>简历信息和照片</span>都正确</span>`"></div>
                <div class="meeting_course_preview_add_page">
                    <customButton class="meeting_course_preview_help_button"  size="small" @click="doCommit()">
                        确定
                    </customButton>
                    <customButton class="meeting_course_preview_help_button" size="small" @click="this.showFirstTip = false">
                        取消
                    </customButton>
                </div>
            </div>
        </Popup>
        <Popup :cfg="popupCfg" v-if="showDeletePopup" @click="()=>{ this.showDeletePopup = false }">
            <div class="meeting_course_preview_tip_content">
                <div class="meeting_course_preview_tip_content_text">请确认是否删除此页？</div>
                <div class="meeting_course_preview_add_page">
                    <customButton class="meeting_course_preview_help_button"  size="small" @click="deletePage(true)">
                        确定
                    </customButton>
                    <customButton class="meeting_course_preview_help_button" size="small" @click="deletePage(false)">
                        取消
                    </customButton>
                </div>
            </div>
        </Popup>
  </div>
</template>
<script>
import customButton  from '@/components/unit/customButton'
import itemScroll  from '@/components/unit/itemScroll'
import opacityBg from "@/components/unit/opacityBg"
import Popup from "@/components/unit/popup"
import { ElInput} from 'element-plus';
export default {
    name:"meetingCourse",
    props:{
        meetingData:{
            type:Object,
            default:()=>{
                return {}
            }
        }
    },
    data(){
        return {
            popupCfg:{
                title:'',
                content:"",
                button:[]
            },
            maxCount:220,
            contentLength:0,
            content:"",
            data:{
                content:""
            },
            doctorInfo:{
                email:""
            },
            doSome:"",
            operating:{
                pptxDownload:"pptxDownload",
                originalHelp:"originalHelp"
            },
            PopopText:{
                pptxDownload:"请输入您的邮箱，稍后我们将幻灯及解说词通过邮件发送至您的邮箱，请注意查收",
                originalHelp:"本平台不提供任何原文资源，仅采用百度学术文献互助平台的C2C模式，业内同道接到您的需求后会分享至您的邮箱。"
            },
            showPopup:false,
            editContent:false,
            contenteditable:false,
            inputLock:false,
            showAddPage:false,
            showDeletePopup:false,
            showFirstTip:false
        }
    },
    components:{
        itemScroll,
        customButton,
        ElInput,
        Popup,
        opacityBg
    },
    created(){
        this.getDoctorInfo()
        this.getContent(true);
    },
    mounted(){
        this.$nextTick(()=>{
            // this.$refs.courseContent.addEventListener("input",{
        })
    },
    methods:{
        async getDoctorInfo(){
            let url = `${this.$urls.doctor.doctorInfo}`
            let response = await this.$axios.get(url)
            this.doctorInfo.email = response.data.email
        },

        isFullPage(){
              let count = this.meetingData?.class_info.content.filter(x=>x.sys_add != 1).length || 0
              console.log(count,'---------------------------------------')
              return count < this.meetingData?.meet_info.max_new_page && this.meetingData?.meet_info.max_new_page
        },
        compositionStart(event){
            this.inputLock = true;
        },
        compositionEnd(event){
            this.inputLock = false;
            this.changeContent(event)
        },
        pasteEvent(event){
            // this.$nextTick(()=>{
            //     let select = window.getSelection();
            //     event.target.innerText = event.target.innerText
            //     select.selectAllChildren(event.target);
            //     select.collapseToEnd();
            // })
        },
        initCourseStatus(data){ },
        initNextCourse(data,isEdit){
            this.getContent()
            if(isEdit || this.isAutoEdit()){
                this.updateContent(false)
            }
        },

        isHaveDoctorPage(){
            return !!this.meetingData?.meet_info.doctor_page
        },
        isAutoEdit(){
            let item = this.meetingData?.class_info.content[this.meetingData.doctor_index]
            let content = item.content.map(x=>x.class_content).filter(x=>x!="")
            let isTemplate = ['SYSTEMCOMMONTEMPLATE','COMMONTEMPLATE'].includes(item.template_key)
            let data = {SYSTEMCOMMONTEMPLATE:2,COMMONTEMPLATE:1}
            return isTemplate && content.length < data[item.template_key]
        },
        getDoctorIntroduce(){
            let item = this.meetingData.class_info.content[0]
            if(this.meetingData.doctor_index != 0 || item.template_key == "DOCTERPAGE") return ''
            let doctroDetail = this.meetingData?.meet_info.introduce ||  `大家好，我是${this.meetingData?.meet_info.doctor_name}，来自${this.meetingData?.meet_info.hospital} ${this.meetingData?.meet_info.department}，今天由我来为大家解读这篇文献`
            if(item.prompt_content.includes("大家好，我是")) return ""
            else{
                return  `${doctroDetail}\n`
            }
        },
        getContent(status,content){
            let doctorDetail = this.getDoctorIntroduce()
            let prompt_content = this.meetingData?.class_info.content[this.meetingData.doctor_index]?.prompt_content ?? "";
            this.content =  content ?? `${doctorDetail}${prompt_content}`;
            this.data.content = content ?? this.content;
            this.maxCount = this.meetingData.doctor_index == 0 &&  this.meetingData.class_info.content[0].template_key == "DOCTERPAGE" ? 220 + doctorDetail.length : 220
            this.getContentLength()
            this.$nextTick(()=>{
                this.$refs.courseContent.innerText = this.content
            })
            if(this.isAutoEdit()){
                this.$nextTick(()=>{
                    this.updateContent(false)
                })
            }
        },
        getContentLength(){
            let content = `${this.data.content}`
            let item =  this.$tools.dealWithText(content);
            this.contentLength = item.count;
            this.data.content = item.content
            // console.log(this.)
        },
        focusContent(event){
            this.$emit("unLockArea")
        },
        changeContent(event){
            console.log(event.inputType)
            if(this.inputLock && event.inputType !== "deleteContentBackward") return;
            let select;
            let dom = event.target;
            let item = this.$tools.dealWithText(dom.innerText);
            let data = this.$tools.dealWithText(dom.innerText,this.maxCount);
            let content =  this.$tools.dealWithText(this.data.content);
            console.log(dom.innerText,data)
            this.data.content = data.content
            this.contentLength = data.count;
            if(content.count>this.maxCount && item.count>this.maxCount)
            {
                select = window.getSelection();
                dom.innerText = this.data.content
                select.selectAllChildren(dom);
                select.collapseToEnd();
                return
            }

            let isEnterEnd = /(\n|\r)$/.test(data.content)
            if(event.inputType == "deleteContentBackward" && this.data.content.length == dom.innerText.length+1){
                this.data.content = this.data.content.slice(0,-1)
                isEnterEnd = true
            }
            if(event.inputType == 'insertFromPaste'){
                isEnterEnd = true
            }
            if(item.count > this.maxCount || isEnterEnd) {
                select = window.getSelection();
                dom.innerText = this.data.content;
                select.selectAllChildren(dom);
                select.collapseToEnd();
            }
            // if(item.count > this.maxCount) {
            //     let select = window.getSelection();
            //     let lastRange = select.getRangeAt(0);
            //     let offset = lastRange.endOffset;
            //     let contentPrep = this.data.content.substr(0,offset);
            //     let contentNext = this.data.content.substr(offset);
            //     dom.innerHTML = `<span>${contentPrep}</span><span>${contentNext}</span>`;
            //     let range = new Range();
            //     range.setStart(dom.children[0],1)
            //     range.setEnd(dom.children[0],1)
            //     select.removeAllRanges()
            //     select.addRange(range)
            // }
        },
        getData(){
            return  {
                id:this.meetingData.meet_info.courseware_id,
                meet_id: this.meetingData.meet_info.id,
                prompt_content:this.data.content,
                content_page:this.meetingData.doctor_index + 1,
                index:this.meetingData.class_info.content[this.meetingData.doctor_index].index
            }
        },
        getPopopText(){
            return this.PopopText[this.doSome]
        },
        getButtonText(){
            let submit_text = this.meetingData.meet_info.submit_text || "进入讨论";
            if(this.isLastPage()) {
                return this.$tools.isPravate()?"预备提交" : submit_text
            };
            return "下一页"
        },
        isShowClear(){
            return ['DOCTERPAGE','TITLETEMPLATE'].includes(this.meetingData?.class_info.content[this.meetingData.doctor_index].template_key)
        },
        getEditContentButtonName(contenteditable){
            let contentName = this.meetingData?.class_info.content[this.meetingData?.doctor_index].template_key == "TITLETEMPLATE" ? "标题" : '内容'
            let buttonName = this.meetingData?.class_info.content[this.meetingData?.doctor_index].content.filter(x=>x.content!="").length == 0 ? "编辑" : "修改"
            return contenteditable? contentName == "内容" ? "保存" : `保存${contentName}`:`${buttonName}${contentName}`
        },
        isBaseTemplate(){
            return this.meetingData?.class_info.content[this.meetingData?.doctor_index].template_key == "BASETEMPLATE"
        },
        getEditDescriptButtonName(contenteditable){
            let buttonName = this.meetingData?.class_info.content[this.meetingData?.doctor_index].prompt_content.length == 0 ? "编辑" : "修改"
            return contenteditable?"保存解说词":`${buttonName}解说词`
        },
        restoreDefault(){
            if(this.editContent){
                this.$emitter.emit(`restore${this.meetingData.doctor_index}`)
            }else{
                this.getContent(false,this.meetingData.class_info.content[this.meetingData.doctor_index].default_content)
            }
        },
        clearInfo(){
            if(this.editContent){
                this.$emitter.emit(`clear${this.meetingData.doctor_index}`)
            }else{
                this.getContent(false,'')
            }
        },
        updateContent(status=true){
            console.log(this.meetingData.doctor_index)
            if(!this.editContent || !status) {
                this.$emitter.emit(`edit${this.meetingData.doctor_index}`,true)
                this.editContent = true
            }
            else{
               this.doSaveInfo(false)
            }
        },
        updateDescription(contenteditable){
            this.contenteditable = !contenteditable
            if(this.contenteditable){
                this.$nextTick(()=>{
                let dom = this.$refs.courseContent
                let select = window.getSelection();
                dom.innerText = this.data.content;
                select.selectAllChildren(dom);
                select.collapseToEnd();
                this.$refs.scrollDOM.scrollTo(0,this.$refs.scrollDOM.scrollHeight)
                })
            }else{
                this.doCommit(false)
            }
        },
        isLastPage(){
            return this.meetingData.doctor_index == this.meetingData.class_info.content.length - 1
        },
        verifyData(){
            // if(this.data.content == "" ){
            //     this.$tips.error({text:"解说词不能为空"})
            //     return false;
            // }
            return true
        },
        setNextStep(phase){
            // this.$emit("setNextStep",{phase})
            this.$emit("setWatchNextStep",phase)
        },
        goToNextPage(){
            this.contenteditable = false
            if(this.meetingData.doctor_index == 0) this.setNextStep(this.$config.categary.phase.meeting_course)
            if(this.isLastPage()) {
                this.setNextStep(this.$config.categary.phase.meeting_discuss)
                return;
            }
            let page = this.meetingData.doctor_index + 1;
            this.$refs.scrollDOM.scrollTo(0,0)
            let data = {
                doctor_index:page,
                live_index:page-1
            }
            this.goToNextPageCustom(data)
        },
        showPopop(doSome){
            this.doSome = doSome
            this.showPopup = true
        },
        doPrepPage(){
            if(this.editContent || this.contenteditable){
                this.$tips.error({text:"请先保存内容"})
                return;
            }
            let page = this.meetingData.doctor_index - 1;
            if(page < 0) return;
            this.$refs.scrollDOM.scrollTo(0,0)
            // this.$emit("sendMessage","meet_next",{
            //     doctor_index:page,
            //     live_index:page > 1 ? page - 1 : 0
            // })
            let data = {
                doctor_index:page,
                live_index:page > 1 ? page - 1 : 0
            }
            this.goToNextPageCustom(data)
        },
        doNewPage(){
            if(this.editContent || this.contenteditable){
                this.$tips.error({text:"请先保存内容"})
                return;
            }
            this.showAddPage = true
        },
        goToNextPageCustom(data){
            this.$emit("setWatchNextPage",data)
        },
        addPage(distance){
            let url = this.$urls.meet.pageContent
            let data = {
                courseware_id: this.meetingData.meet_info.courseware_id,
                meet_id: this.meetingData.meet_info.id,
                template_id: this.meetingData.meet_info.new_page_template, // 目前固定模板，后继如需选模板再改
                meet_id:this.meetingData.meet_info.id,
                content_page: this.meetingData.doctor_index+ distance + 1
            }
            this.$axios.post(url,data).then(res=>{
                this.$emit('addPage',data.content_page - 1 ,res.data)
                this.showAddPage = false
            })
        },
        doDeletePage(){
            this.showDeletePopup = true;
        },
        deletePage(status){
            this.showDeletePopup = false
            if(!status) return;
            let data = {
                meet_id:this.meetingData.meet_info.id,
                courseware_id: this.meetingData.meet_info.courseware_id,
                meet_id: this.meetingData.meet_info.id,
                content_page: this.meetingData.doctor_index + 1
            }
            let url = this.$tools.getURL(this.$urls.meet.pageContent,data)
            this.$axios.delete(url).then(res=>{
                this.$emitter.emit(`edit${this.meetingData.doctor_index}`,false)
                this.$emit('removePage',this.meetingData.doctor_index)
                this.editContent = false
                this.contenteditable = false
            })
        },
        doSaveInfo(isNext,callback){
                this.$emitter.emit(`save${this.meetingData.doctor_index}`,(res)=>{
                    this.$emitter.emit(`edit${this.meetingData.doctor_index}`,false)
                    this.$emit('saveInfo',this.meetingData.doctor_index,res)
                    this.editContent = false;
                    callback && callback(res)
                },()=>{
                    setTimeout(()=>{ this.loading = false},1000)
                })
        },
        doCommit(isNext=true,isTip=false){
            if(isTip && this.meetingData.meet_info.show_home_tip !== 0 && this.meetingData.class_info.content[this.meetingData.doctor_index].template_key == "DOCTERPAGE"){
                this.showFirstTip = true;
                return;
            }
            if(this.loading) return;
            this.loading = true;
            this.showFirstTip = false
            document.body.scrollTo(0,0)
            if(this.editContent){
                this.doSaveInfo(isNext,(res)=>{
                    console.log(res)
                    this.doSaveContent(isNext)
                })
            }else{
                this.doSaveContent(isNext)
            }

        },
        doSaveContent(isNext){
            if(!this.verifyData()) {
                setTimeout(()=>{ this.loading = false},1000)
                return;
            }
            this.$axios.patch(this.$urls.meet.customQuestion,this.getData()).then(res=>{
                setTimeout(()=>{ this.loading = false},1000)
                this.$emit('savePromptContent',this.meetingData.doctor_index,this.data.content)
                if(isNext){
                    this.goToNextPage()
                }
            }).catch(err=>{
                setTimeout(()=>{ this.loading = false},1000)
            })
        },
        commitEmail(){
            if(!(/[\w.-]+@[\w-]+\.[\w-]+/g.test(this.doctorInfo.email))){
                this.$tips.error({text:'请输入正确的邮箱'});
                return false;
            }
            if(this.doSome ==="pptxDownload"){
                this.pptxDownload()
            }
            if(this.doSome ==="originalHelp"){
                this.originalHelp()
            }
        },
        pptxDownload(){
            let courseware_id = this.meetingData.meet_info.courseware_id
            let url = this.$tools.getURL(this.$urls.api.pptxDownload,{id:courseware_id,email:this.doctorInfo.email,type:1})
            this.$axios.get(url).then(res=>{
                this.$tips.success({text:"发送成功"});
                this.showPopup = false;
            }).catch(err=>{
                this.showPopup = false;
            })
        },
        originalHelp(){
            let courseware_id = this.meetingData.meet_info.courseware_id
            let url = this.$tools.getURL(this.$urls.api.sendEmail,{id:courseware_id,email:this.doctorInfo.email,type:1})
            this.$axios.get(url).then(res=>{
                this.$tips.success({text:"求助成功"});
                this.showPopup = false;
            }).catch(err=>{
                this.showPopup = false;
            })
        },
        hidePopup(){
            this.showPopup = false;
        },
    }
}
</script>
<style>
.meeting_course_preview_container{
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}
.meeting_course_preview_room{
  flex:1 0 0;
  width:100%;
  padding-top:4vw;
  padding-bottom:4vw;
  overflow: hidden;
  position: relative;
  min-height:20vw;
}
.meeting_course_preview_room_proxy{
  overflow: scroll;
  height:100%;
  position: relative;
  /* background:red; */
}
.meeting_course_preview_group{
  flex:12vw 0 0;
  background:#fff;
  width:100%;
  padding:0 3.2vw;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.meeting_course_preview_step_detail{
    flex:1 0 0;
    height:8.33vw;
    margin-right:2vw;
    display: flex;
    align-items: center;
}
.meeting_course_preview_step_time>span:nth-child(2){
    color:#0100ca;
    /* font-size:4vw; */
}
.meeting_course_preview_content{
    line-height:6vw;
    font-size:4vw;
    user-select: text !important;
    -moz-user-select: text !important;
    -webkit-user-select:text !important;
    white-space: pre-wrap;
    min-height:100%;
}
.meeting_course_preview_content textarea{
    border:none;
    background: transparent;
    padding:none;
    margin:none;
    font-size:4vw;
    line-height:6vw;
    height:100%;
}
.meeting_course_preview_content .el-textarea{
    font-size:4vw;
    height:100%;
}
.meeting_course_preview_tips_save{
    width: 100%;
    padding: 0 4vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: -9.26vw;
    z-index: 50;
    height:8.26vw;
    background:#7fb8ff;

}
.meeting_course_preview_tips_save_countLength{
    /* flex:1 0 0; */
    height:8.26vw;
    display: flex;
    align-items: center;
}
.meeting_course_preview_tips_save_title{
    display: flex;
    align-items: center;
    justify-content: center;
}
.meeting_course_preview_tips_save_buttonrow{
    flex:1 0 0;
    height:7vw;
    /* margin-right:4vw; */
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.meeting_course_preview_tips_save_button{
    height: 7vw;
}
.meeting_course_preview_step{
    padding: 0 2vw;
}
.meeting_course_preview_help_block{
    background: #fff;
    width: 60%;
    padding:4vw;
    border-radius: 1vw;
}
.meeting_course_preview_help_tips{
    line-height: 7vw;
}
.meeting_course_preview_help_email{
    margin: 8vw 0 2vw 0;
    color: #0100c8;
}
.meeting_course_preview_help_input{
    border:none;
    padding: 0;
}
.meeting_course_preview_help_buttonGroup{
    width: 100%;
    height: 8vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
}
.meeting_course_preview_help_button{
    width: 20vw;
    height: 8vw;
    padding: 1vw;
    border-radius: 1vw;
}
.meeting_course_preview_help_button_info{
    height:100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
}
.meeting_course_preview_update{
    /* background: #578cf5; */
    background-image: linear-gradient(to top right, #0c2dd1, #638ee4) !important;
    color: #fff;
    /* border: 1px #000 solid; */
    border-radius: 1vw;
}
.meeting_course_preview_tip_content{
    /* display: flex; */
    /* flex-direction: column; */
    padding:4vw 0;
}
.meeting_course_preview_tip_content_text{
    display: flex;
    align-items: center;
    height: 16vw;;
}
.meeting_course_preview_add_page{
    display: flex;
    justify-content: center;
}
.meeting_course_preview_add_page .meeting_course_preview_help_button:first-child{
    margin-right:1vw;
}
.meeting_course_preview_step_button{
    flex:1 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.meeting_course_preview_step_button:first-child{
    justify-content: flex-start;
}
.meeting_course_preview_step_button:last-child{
    justify-content: flex-end;
}
.meeting_course_preview_time_count{
    color:red !important;
}
</style>
